import React, {useContext, useEffect, useState} from 'react'
import {SiteContext} from '../../context/SiteContext'
import {LuCalendarDays, LuUser} from 'react-icons/lu'
import {BiMoneyWithdraw} from 'react-icons/bi'
import {Button, FormControl, Input} from '../../components/atoms'
import {BlockTitle, ComboFondo, DialogChangeIniAmount, DialogConfirm, DialogMoney, SidebarHistory} from '../../components/blocks'
import {useMutation, useQuery} from '@tanstack/react-query'
import axios from 'axios'
import {Apis} from '../../utils/apis'
import {toast} from '../../hooks/useToast'
import {useNavigate} from 'react-router-dom'
import {cn, formatNumber} from '../../utils/general'
import HisSaleTicket from "../historial/hisSaleTicket";
import HisRedeemTickets from "../historial/hisRedeemTickets";
import HisHandpay from "../historial/hisHandpay";
import HisCardpay from "../historial/hisCardpay";
import HisRefill from "../historial/hisRefill";
import HisExpenses from "../historial/hisExpenses";
import HisExchangeRate from "../historial/hisExchangeRate";
import HisBill from "../historial/hisBill";
import {TbEdit} from 'react-icons/tb'
import styles from "../operations/operations.module.css";
import useEndpoint, {salasBeta} from "../../hooks/useEndpoint";

const TextValue = ({text, value}) => {
  return (
    <div className='flex flex-col items-end gap-1 px-2'>
      <span className='text-xl font-bold'>{value}</span>
      <span className='text-sm font-light text-neutral-500'>{text}</span>
    </div>
  )
}

const OperationLinkSummary = ({text, quantity, value, onClick}) => {
  return (
    <div className='flex flex-row self-stretch justify-between gap-4'>
      <span className='history' onClick={onClick}>{text + " (" + quantity + ")"}</span>
      <span className='text-right'>{value}</span>
    </div>
  )
}

const CloseTurn = () => {

  const {userInfo, fondo, config, printer, salaId} = useContext(SiteContext);
  const [amount, setAmount] = useState('');
  const [selectedFondo, setSelectedFondo] = useState(0);
  const [openMoney, setOpenMoney] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openIniAmountDialog, setOpenIniAmountDialog] = useState(false);  
  const navigate = useNavigate();
  const [openHistory, setOpenHistory] = useState(false);
  const [content, setContent] = useState(<span>---</span>);
  const [title, setTitle] = useState('');

  let getPreArqueoEndpoint = useEndpoint('getPreArqueo');

  if (salasBeta(salaId)) {
    getPreArqueoEndpoint = `${Apis.CAJA}/arqueo/pre/v2`;
  }

  const {data, error, isSuccess, isError, refetch} = useQuery({
    queryKey: ['getPreArqueo', fondo?.id, amount],
    queryFn: () =>
      axios.post(Apis.CAJA + '/arqueo/pre/v2', {
        fondoId: fondo?.id,
        foundedAmount: amount || 0,
      }).then((res) => res.data),
    enabled: !!fondo?.id,
  });

  const {
    data: dataLocal,
    error: errorLocal,
    isSuccess: isSuccessLocal,
    isError: isErrorLocal,
    refetch: refetchLocal
  } = useQuery({
    queryKey: ['getPreArqueoLocal', fondo?.id, amount],
    queryFn: () =>
      axios.post(getPreArqueoEndpoint, {
        fondoId: fondo?.id,
        foundedAmount: amount || 0,
      }).then((res) => res.data),
    enabled: !!fondo?.id,
  });

  useEffect(() => {
    if (isSuccess && data) {
      toast({
        title: 'Operación realizada',
        variant: 'success',
        duration: 2000,
      });
    }
  }, [isSuccess, data, isSuccessLocal, dataLocal]);

  useEffect(() => {
    if (isError && error) {
      const errorMessage = error?.response?.data?.message || 'Ha ocurrido un error en la solicitud';
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000,
      });
    }
  }, [isError, error , isErrorLocal, errorLocal]);

  useEffect(() => {
    if (fondo?.id && (amount >= 0)) {
      refetch();
    }
  }, [amount, refetch, fondo?.id , refetchLocal]);

  const closeTurnMutation = useMutation({
    mutationKey: ['close_turn'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/fondos/' + fondo.id + '/arqueo-and-close', data),
    onSuccess: () => {
      navigate('/logout');
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
      setOpenConfirmDialog(false)
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleConfirmCloseTurn = () => {
    if (amount >= 0) {
      closeTurnMutation.mutate({
        fondoId: fondo.id,
        foundedAmount: amount,
        targetFondoId: selectedFondo?.id,
        printerId: printer?.printerId
      });
    }
  };

  const handleOpenHistory = (code, newTitle) => {
    //console.log("Cambiando contenido y título a:", newTitle);
    setTitle(newTitle);

    // Solo abre si no está ya abierto
    if (!openHistory) {
      //console.log("Abriendo el Sheet...");
      setOpenHistory(true);
    }

    switch (code) {
      case 1:
        setContent(<HisSaleTicket/>);
        break;
      case 2:
        setContent(<HisBill/>);
        break;
      case 3:
        setContent(<HisRedeemTickets/>);
        break;
      case 4:
        setContent(<HisHandpay/>);
        break;
      case 5:
        setContent(<HisCardpay/>);
        break;
      case 6:
        setContent(<HisRefill/>);
        break;
      case 7:
        setContent(<HisExpenses/>);
        break;
      case 8:
        setContent(<HisExchangeRate/>);
        break;
      case 9:
        setContent(<HisSaleTicket restricted={true}/>);
        break;
      default:
        setContent(<span>---</span>);
        break;
    }
  };

  useEffect(() => {
    //console.log("Estado actual de openHistory:", openHistory); // Verifica el estado en cada render
  }, [openHistory]);

  useEffect(() => {
    const sheetElement = document.querySelector("[data-state]");
    if (sheetElement) {
      //console.log("Estado del Sheet:", sheetElement.getAttribute("data-state"));
    }
  }, [openHistory, content]);

  return (
    <div className='flex flex-col flex-1 gap-4'>
      <div className='flex flex-row items-center gap-4 max-[1024px]:hidden'>
        <div className='flex flex-row items-center flex-1 gap-2 p-4 border rounded-2xl border-neutral-800'>
          <div className='flex flex-col flex-1'>
            <span className='font-bold'>{userInfo?.user?.name + ' ' + userInfo?.user?.lastName}</span>
            <span className='text-xs font-light'>Cajero</span>
          </div>
          <div className='p-3 bg-neutral-800 rounded-xl'>
            <LuUser size={22}/>
          </div>
        </div>
        <div className='flex flex-row flex-1 gap-2 p-4 border rounded-2xl border-neutral-800'>
          <div className='flex flex-col flex-1'>
            <span className='font-bold'>{fondo?.name}</span>
            <span className='text-xs font-light'>Fondo</span>
          </div>
          <div className='p-3 bg-neutral-800 rounded-xl'>
            <BiMoneyWithdraw size={22}/>
          </div>
        </div>
        <div className='flex flex-row flex-1 gap-2 p-4 border rounded-2xl border-neutral-800'>
          <div className='flex flex-col flex-1'>
            <span className='font-bold'>{fondo?.period}</span>
            <span className='text-xs font-light'>Periodo</span>
          </div>
          <div className='p-3 bg-neutral-800 rounded-xl'>
            <LuCalendarDays size={22}/>
          </div>
        </div>
      </div>

      <BlockTitle title='CIERRE DE TURNO' className='m-0'/>

      <SidebarHistory
        open={openHistory}
        setOpen={setOpenHistory}
        showTrigger={false}
        title={title}
      >
        {content}
      </SidebarHistory>

      {
        data && dataLocal &&
        <div className='flex flex-row self-stretch gap-4'>

          <div className='flex flex-col flex-1 gap-4'>
            <div className='flex flex-col flex-1 gap-4 p-4 bg-amber-200 text-neutral-950 rounded-2xl'>
              <span className='text-lg font-bold'>Operaciones</span>
              <div className='flex flex-col gap-4'>
                <span className='font-bold'>Entradas</span>
                <div className='flex flex-col gap-4 px-2'>

                  <OperationLinkSummary 
                    text={'Venta de tickets'}
                    className="no-close"
                    quantity={data.ticketVentaQuantity !== dataLocal.ticketVentaQuantity ? data.ticketVentaQuantity : dataLocal.ticketVentaQuantity}
                    value={formatNumber(data.ticketVenta !== dataLocal.ticketVenta ? data.ticketVenta : dataLocal.ticketVenta, config.symbol)}
                    onClick={() => handleOpenHistory(1, 'Historial - Venta de tickets')}
                  />

                  <OperationLinkSummary 
                    text={'Billetero de tickets'}
                    className="no-close"
                    quantity={data.billQuantity}
                    value={formatNumber(data.bill, config.symbol)}
                    onClick={() => handleOpenHistory(2, 'Historial - Billetero')}
                  />

                </div>
              </div>
              <div className='flex flex-col gap-4'>
                <span className='font-bold'>Salidas</span>
                <div className='flex flex-col gap-4 px-2'>

                  <OperationLinkSummary 
                    text={'Canje de tickets'}
                    className="no-close"
                    quantity={data.ticketRedeemQuantity !== dataLocal.ticketRedeemQuantity ? data.ticketRedeemQuantity : dataLocal.ticketRedeemQuantity}
                    value={formatNumber(data.ticket !== dataLocal.ticket ? data.ticket : dataLocal.ticket, config.symbol)}
                    onClick={() => handleOpenHistory(3, 'Historial - Canje de tickets')}
                  />

                  <OperationLinkSummary 
                    text={'Pagos manuales'}
                    className="no-close"
                    quantity={data.handpayQuantity !== dataLocal.handpayQuantity ? data.handpayQuantity : dataLocal.handpayQuantity}
                    value={formatNumber(data.handpay !== dataLocal.handpay ? data.handpay : dataLocal.handpay, config.symbol)}
                    onClick={() => handleOpenHistory(4, 'Historial - Pagos manuales')}
                  />

                  <OperationLinkSummary 
                    text={'Pagos con tarjeta'}
                    className="no-close"
                    quantity={data.cardpayQuantity !== dataLocal.cardpayQuantity ? data.cardpayQuantity : dataLocal.cardpayQuantity}
                    value={formatNumber(data.cardpay !== dataLocal.cardpay ? data.cardpay : dataLocal.cardpay, config.symbol)}
                    onClick={() => handleOpenHistory(5, 'Historial - Pagos con tarjeta')}
                  />

                  <OperationLinkSummary 
                    text={'Rellenos'}
                    className="no-close"
                    quantity={data.refillQuantity}
                    value={formatNumber(data.refill, config.symbol)}
                    onClick={() => handleOpenHistory(6, 'Historial - Rellenos')}
                  />

                  <OperationLinkSummary 
                    text={'Gastos'}
                    className="no-close"
                    quantity={data.otherQuantity}
                    value={formatNumber(data.other, config.symbol)}
                    onClick={() => handleOpenHistory(7, 'Historial - Gastos')}
                  />

                </div>
              </div>

              <span className='border-b border-dashed border-neutral-700'/>
              <div className='flex flex-row self-stretch gap-4 px-2 pb-2'>
                <span className='flex-1 text-lg font-bold'>Operaciones</span>
                <span
                  className='text-lg font-bold text-right'>{formatNumber(data?.totalOperationNoTransfer, config.symbol)}</span>
              </div>
            </div>

            <div className='flex flex-col flex-1 gap-4 p-4 bg-amber-200 text-neutral-950 rounded-2xl'>
              <span className='text-lg font-bold'>Otros</span>

              <OperationLinkSummary 
                text={'Promocionales restringidos'}
                className="no-close"
                quantity={data.ticketVentaRestrictedQuantity}
                value={formatNumber(data.ticketVentaRestricted, config.symbol)}
                onClick={() => handleOpenHistory(9, 'Promocionales restringidos')}
              />

              <OperationLinkSummary 
                text={'Cambios de moneda'}
                className="no-close"
                quantity={data.ticketVentaRestrictedQuantity}
                value={data.currencyArqueo[0] !== null ? formatNumber(data.currencyArqueo[0]?.amount, '$') : '--'}
                onClick={() => handleOpenHistory(8, 'Cambios de moneda')}
              />

            </div>
          </div>
          <div className='flex flex-col self-start flex-1 gap-6 p-4 border border-neutral-800 rounded-2xl'>
            <span className='text-lg font-bold'>Monto cierre</span>
            <FormControl>
              <label>Ingrese monto</label>
              <Input
                placeholder="00.0"
                type='number'
                className='text-2xl font-semibold cursor-pointer'
                autoComplete="off"
                value={amount}
                readOnly
                required
                onChange={(e) => setAmount(e.target.value)}
                onClick={() => setOpenMoney(true)}
              />
            </FormControl>

          </div>

          <div className='flex flex-col flex-1 gap-4'>
            <Button
              size='lg'
              disabled={!amount}
              onClick={() => setOpenConfirmDialog(true)}>
              Cerrar turno
            </Button>
            <div className='flex flex-col items-end gap-3 p-4 border border-neutral-800 rounded-2xl'>
              <span className='text-lg font-bold'>Transferencias</span>
              <div className='flex flex-row items-center gap-2'>
              <Button
                variant='outline'
                className='p-0 rounded-xl w-9 h-9'
                onClick={() => setOpenIniAmountDialog(true)}
              >
                <TbEdit size={20} />
              </Button>
              <TextValue
                text={'Monto Inicial'}
                value={formatNumber(data?.iniAmount, config.symbol)}
              />
              </div>
              <TextValue
                text={'Incrementos'}
                value={formatNumber(data?.transferNoIniAmount, config.symbol)}
              />
            </div>

            <div className='flex flex-col items-end gap-3 p-4 border border-neutral-800 rounded-2xl'>
              <span className='text-lg font-bold'>Monto de cierre</span>
              <TextValue
                text={'Soles'}
                value={data?.foundedAmount !== null ? formatNumber(data?.foundedAmount, config.symbol) : '--'}
              />
              {/* 
              <TextValue 
                text={'Dolares'}
                value={data?.currencyArqueo[0] !== null ? formatNumber(data?.currencyArqueo[0]?.amountFound, '$') : '--'}
              />
              */}
            </div>

            <div
              className='flex flex-col items-end gap-3 p-4 bg-transparent bg-white border rounded-2xl text-neutral-950 border-neutral-800'>
              <span className='text-lg font-bold'>Deberias tener</span>
              <div className='flex flex-row items-center w-full gap-2 px-2'>
                <span className='flex-1 text-sm font-light'>Soles</span>
                <span className='text-2xl font-bold text-right'>{formatNumber(data?.totalOperation, config.symbol)}</span>
              </div>
            </div>

            {
              data.foundedAmount > 0 &&
              <div className={cn('flex flex-col items-end gap-4 p-4 rounded-2xl text-neutral-950',
                {
                  'bg-lime-200': data.diff >= 0,
                  'bg-red-400': data.diff < 0,
                  'bg-transparent border border-neutral-800 text-white': !data
                })}>
                <span className='text-lg font-bold'>{data.diff > 0 ? "Sobrante" : "Faltante"}</span>
                <div className='flex flex-row items-center w-full gap-2 px-2'>
                  <span className='flex-1 text-sm font-light'>Soles</span>
                  <span className='text-2xl font-bold text-right'>{formatNumber(data?.diff, config.symbol)}</span>
                </div>
              </div>
            }
          </div>

        </div>
      }

      <DialogMoney
        open={openMoney}
        setOpen={setOpenMoney}
        setAmount={setAmount}
      />
      
      <DialogChangeIniAmount
        open={openIniAmountDialog}
        setOpen={setOpenIniAmountDialog}
        id={fondo?.id}
      />

      <DialogConfirm
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={handleConfirmCloseTurn}
        disabled={closeTurnMutation.isPending || (selectedFondo !== null && selectedFondo === 0)}
        body={
          <div className='flex flex-col gap-6 py-4'>
            <div className='flex flex-col gap-4'>

              <div className="flex flex-row">
                <span className='flex-1'>Monto de cierre: </span>
                <span
                  className='text-lg font-bold text-right'>{data && data.foundedAmount ? `${config.symbol} ${Number(data.foundedAmount).toFixed(2)}` : '--'}</span>
              </div>

              <div className="flex flex-row">
                <span className='flex-1'>{data && data.diff > 0 ? 'Sobrante' : 'Faltante'} </span>
                <span
                  className='text-lg font-bold text-right'>{data && data.diff ? `${config.symbol} ${Number(data.diff).toFixed(2)}` : '--'}</span>
              </div>

              <FormControl>
                <span className='flex-1'>¿Que hacer con el monto de cierre?</span>
                <div className='flex flex-row items-center self-stretch flex-1 gap-4'>
                  <div
                    className={selectedFondo !== null ? styles.selectedButton : styles.buttonGroup}
                    onClick={() => setSelectedFondo(0)}
                  >
                    Enviar a fondo
                  </div>
                  <div
                    className={selectedFondo === null ? styles.selectedButton : styles.buttonGroup}
                    onClick={() => setSelectedFondo(null)}
                  >
                    Reservar el monto
                  </div>
                </div>
              </FormControl>

              {
                selectedFondo !== null &&
                <FormControl>
                  <label>Fondo de destino</label>
                  <ComboFondo selectedFondo={selectedFondo} setSelectedFondo={setSelectedFondo}/>
                </FormControl>
              }

              {
                selectedFondo === null ?
                <span>El monto se reservara para que otro turno de caja pueda tomarlo.</span> :
                  <span>El monto sera transferido al fondo que selecciones.</span>
              }
            </div>
          </div>
        }
      />
    </div>
  )
}

export default CloseTurn
