import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, FormControl, Input} from '../../components/atoms'
import {TbAlertCircleFilled, TbCircleCheckFilled, TbCircleXFilled} from 'react-icons/tb';
import {useQuery} from '@tanstack/react-query'
import axios from 'axios';
import {SiteContext} from '../../context/SiteContext';
import {FaSpinner} from 'react-icons/fa';
import {BlockTitle, ComboClient, CreateTicket, SidebarHistory, TicketDetails} from "../../components/blocks";
import HisRedeemTickets from "../historial/hisRedeemTickets";
import useEndpoint from "../../hooks/useEndpoint";
import {BiBarcodeReader} from 'react-icons/bi';

const RedeemTickets = ({onFocusInput}) => {

  const {fondo} = useContext(SiteContext);
  const redeemEndpoint = useEndpoint('redeemTicket');

  const [ticketCode, setTicketCode] = useState('');
  const [ticketData, setTicketData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [isValidated, setIsValidated] = useState(false);

  const {refetch, isFetching} = useQuery({
    queryKey: ['getTicketRedeem'],
    queryFn: async () => {
      const response = await axios.post(redeemEndpoint, {
        vn: ticketCode,
        fondoId: fondo.id,
        customerId: selectedCustomer?.id || null
      });
      setTicketData(response.data);
      return response.data;
    },
    enabled: false,
    gcTime: 1
  })

  useEffect(() => {
    if (onFocusInput) {
      onFocusInput(() => {
        inputRef.current?.focus();
      });
    }
  }, [onFocusInput]);

  const getMessageByTicketStatus = (message, errorCode, ticketInfo) => {
    const messages = {
      3: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>{message}</span>
            <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>,
          </div>
          <span>Vuelva a ingresar el número de ticket o verifique que está bien escrito</span>
        </>
      ),
      1: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>{message}</span>
            <TbAlertCircleFilled size={28} className='text-red-500 animate-bounce'/>
          </div>
          <TicketDetails ticketData={{ticketInfo, code: errorCode}}/>
        </>
      ),
      2: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>{message}</span>
            <TbAlertCircleFilled size={28} className='text-amber-500 animate-bounce'/>
          </div>
          <TicketDetails ticketData={{ticketInfo, code: errorCode}}/>
        </>
      ),
      4: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>Ticket promocional</span>
            <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>
          </div>
          <span>Ticket promocional (restringido), no se puede canjear en caja.</span>
        </>
      ),
      7: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>Ticket de pago manual</span>
            <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>
          </div>
          <span>El ticket es un recibo de pago manual. No puede canjearse.</span>
        </>
      ),
      8: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>Necesita un cliente para canjear este ticket</span>
            <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>
          </div>
          <span>Revise si el ticket tiene un monto mayor al configurado.</span>
        </>
      ),
      9: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>El ticket no es canjeable</span>
            <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>
          </div>
          <span>El estado del ticket no es cobrable.</span>
        </>
      ),
      default: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>Hubo un error</span>
            <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>
          </div>
          <span>--</span>
        </>
      ),
    };

    return messages[errorCode] || messages.default;
  };

  const renderTicketResponse = (ticketData) => {
    if (ticketData?.success) {
      return (
        <div className='flex flex-col gap-6'>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>Ticket canjeado exitosamente</span>
            <TbCircleCheckFilled size={28} className='text-lime-400 animate-bounce'/>
          </div>
          <TicketDetails ticketData={ticketData}/>
        </div>
      );
    } else {
      return (
        getMessageByTicketStatus(ticketData?.message, ticketData?.code, ticketData?.ticketInfo)
      )
    }
  };

  const handleTicketValidation = (event) => {
    event.preventDefault();

    if (ticketCode.length !== 18) {
      setErrorMessage(`Error en la lectura del Ticket: ${ticketCode}`);
      setTicketCode('');
      return;
    }

    setIsValidated(true);
    setErrorMessage('');
    refetch();
  };

  const handleClear = (event) => {
    event.preventDefault();
    inputRef.current.focus();
    setTicketCode('');
    setIsValidated(false);
    setTicketData(null)
    setErrorMessage('');
    setSelectedCustomer(null);
  };

  useEffect(() => {
    if (ticketData?.code === 3 || ticketData?.code === 8) {
      setIsValidated(false);
      setErrorMessage(ticketData?.message);
    }
  }, [ticketData]);


  return (
    <>
      <BlockTitle title='CANJEAR TICKET'/>

      <SidebarHistory
        title='Historial - Canje de tickets'
        className='mb-2'
      >
        <HisRedeemTickets />
      </SidebarHistory>

      <div className='flex flex-col self-stretch flex-1 gap-6 p-4 bg-neutral-800 rounded-2xl'>
        <form onSubmit={isValidated ? handleClear : handleTicketValidation}>
          <div className='flex flex-col gap-6'>

            <FormControl>
              <label>Ingrese el número de ticket</label>
              <Input
                placeholder="Nro. de ticket"
                type='text'
                id='auto-focus-input'
                ref={inputRef}
                value={ticketCode}
                autoFocus
                autoComplete="off"
                onChange={(e) => setTicketCode(e.target.value)}
              />

              {
                ticketData?.code === 8 &&
                <ComboClient setSelectedCustomer={setSelectedCustomer} selectedCustomer={selectedCustomer}/>
              }

            </FormControl>
            <Button size='lg' type="submit" variant={isValidated ? 'secondary' : 'default'} disabled={isFetching}>
              {isValidated ? 'Limpiar' : 'Canjear ticket'}
              <span style={{
                backgroundColor: 'rgba(155, 120, 23)',
                color: 'white',
                fontFamily: '"Courier New", Courier, monospace',
                fontSize: '15px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                borderRadius: '4px',
                padding: '0 6px',
              }}>Enter</span>
            </Button>
            {errorMessage && ( // Mostrar el mensaje de error si existe
              <div className='flex flex-col items-center justify-center gap-1'>
                <div className='flex flex-row items-center gap-2'>
                  <span className='text-xl font-bold'>{errorMessage}</span>
                  <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>
                </div>
                {ticketCode.length !== 18 && 
                  <>
                    <span className='text-lg'>Escanee nuevamente o escriba el código</span>
                    <BiBarcodeReader size={42} />
                  </>
                }
              </div>
            )}
            {isValidated && (
              <div className='flex flex-col gap-4'>
                {isFetching ?
                  <div
                    className='flex flex-col items-center justify-center w-full gap-4 py-6 select-none animate-pulse'>
                    <FaSpinner className='animate-spin' size={24}/>
                    <span>Cargando...</span>
                  </div>
                  :
                  renderTicketResponse(ticketData)
                }
              </div>
            )}
            {ticketData?.code === 3 &&
              <CreateTicket validNumber={ticketCode}/>
            }
          </div>
        </form>
      </div>
    </>
  )
}

export default RedeemTickets
