import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {Apis} from "../../../utils/apis";
import {Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogOverlay, DialogTitle} from "../../atoms";
import {GiPadlock} from "react-icons/gi";
import {useNavigate} from "react-router-dom";

const Banner = () => {

  const [showFullBlock, setShowFullBlock] = useState(false)

  const {data} = useQuery({
    queryKey: ['getBanner'],
    queryFn: async () => await axios.get(`${Apis.SALAS}/banner-by-sala`)
      .then((res) => res.data)
      .catch((err) => {
        console.error('Error fetching banner', err);
        throw err;
      }),
  })

  const navigate = useNavigate();

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && data[0]?.title) {
      if (data[0].title === "Servicio suspendido") {
        setShowFullBlock(true);
      } else {
        setShowFullBlock(false);
      }
    } else {
      setShowFullBlock(false)
    }
  }, [data]);

  return (
    <div>
      {
        showFullBlock && (
          <Dialog open={showFullBlock} onClose={() => setShowFullBlock(false)}>
            <DialogOverlay/>
            <DialogContent className='flex flex-col gap-4 bg-white' showCloseButton={false}>
              <DialogHeader>
                <DialogTitle className={'text-center text-3xl'}>
                  <div className={'flex justify-center p-8'}>
                    <GiPadlock size={100} style={{color: '#ffa600'}}/>
                  </div>
                  <p style={{color: 'black'}}>
                    {data ? data[0].title : ''}
                  </p>
                </DialogTitle>
                <DialogDescription className={'text-center text-1xl'}>
                  {data ? data[0].message : ''}
                  <div className={'flex justify-center p-2'}>
                    <Button
                      variant="default"
                      onClick={() => navigate('/selector')}
                    >
                      Aceptar
                    </Button>
                  </div>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        )
      }

    </div>
  );
};

export default Banner;
