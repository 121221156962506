import React, {useContext, useState} from 'react'
import {Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogOverlay, DialogTitle, FormControl, Input} from '../../atoms'
import DialogConfirm from '../dialog-confirm';
import {formatNumber} from '../../../utils/general';
import {toast} from '../../../hooks/useToast';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../../utils/apis';
import {SiteContext} from '../../../context/SiteContext';
import {FaCircleExclamation} from 'react-icons/fa6';

const DialogChangeIniAmount = ({open, setOpen,id}) => {

  const [iniAmount, setIniAmount] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const {config} = useContext(SiteContext);

  const queryClient = useQueryClient();

  const iniAmountMutation = useMutation({
    mutationKey: ['createTurn'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/fondos/'+id+'/ini-transfer', data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['getPreArqueo']});
      queryClient.invalidateQueries({queryKey: ['getPreArqueoLocal']});
      queryClient.invalidateQueries({queryKey: ['getTransfers']});
      toast({
        title: "Operación realizada",
        variant: 'success',
        duration: 2000
      })
      setConfirmOpen(false)
      setOpen(false)
      setIniAmount('')
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = () => {
    iniAmountMutation.mutate({
      amount: iniAmount
    });
  };

  const handleConfirm = () => {
    handleSubmit();
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogOverlay />
        <DialogContent className='flex flex-col gap-4'>
          <DialogHeader>
            <DialogTitle>Monto inicial</DialogTitle>
            <DialogDescription>
              Por favor, ingrese el monto:
            </DialogDescription>
          </DialogHeader>
          <form 
            className='flex flex-col gap-4' 
            onSubmit={(event) => {
              event.preventDefault();
              setConfirmOpen(true);
            }}>
          <FormControl>
            <label>Ingrese el monto Inicial</label>
            <Input
              type="number"
              value={iniAmount}
              onChange={(e) => setIniAmount(e.target.value)}
              placeholder="0"
              className='h-12 text-base placeholder:text-base'
            />
          </FormControl>
          <div className='flex flex-row items-center justify-end gap-2'>
            <Button type='button' variant='outline' onClick={() => setOpen(false)}>Cancelar</Button>
            <Button type='submit'>Aceptar</Button>
          </div>
          </form>
        </DialogContent>

      </Dialog>
      <DialogConfirm
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
        disabled={iniAmountMutation.isPending}
        body={
          <div className='flex flex-col gap-4 my-4'>
            <span className='mb-4 text-neutral-500'>¿El monto inicial es correcto?</span>
            <div className='flex flex-row items-end gap-3'>
              <span>Monto inicial:</span>
              <span className='text-xl font-bold'>{formatNumber(iniAmount, config.symbol)}</span>
              <FaCircleExclamation className='animate-bounce text-amber-400' size={28}/>
            </div>
          </div>
        }
      />
    </>
  )
}

export default DialogChangeIniAmount
