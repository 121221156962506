import React, {useContext, useMemo} from 'react'
import {SiteContext} from '../../context/SiteContext';
import {useMutation, useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {AsyncContent, EmptyState, ReactTable} from '../../components/blocks';
import {format, parseISO} from 'date-fns';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import {Button} from '../../components/atoms';
import {formatNumber} from '../../utils/general';
import {toast} from '../../hooks/useToast';
import CardSummary from "./hisUtils";
import useEndpoint, {salasBeta} from "../../hooks/useEndpoint";

const HisHandpay = () => {

  const {fondo, config, printer, salaId} = useContext(SiteContext);

  let hisHandPayEndpoint = useEndpoint('hisHandPay');
  let deleteHandPayEndpoint = useEndpoint('deleteHandPay');

  if (salasBeta(salaId)) {
    hisHandPayEndpoint = `${Apis.CAJA}/operation-handpay/report/by-fondo`;
    deleteHandPayEndpoint = `${Apis.CAJA}/operation-handpay/`;
  }

  const {data, isFetched, isLoading, isError, error} = useQuery({
    queryKey: ['getHandpays', fondo?.id],
    queryFn: async () => await axios.get(`${hisHandPayEndpoint}?fondoId=` + fondo?.id)
      .then((res) => res.data)
      .catch((err) => {
        console.error('Error fetching handpays history', err);
        throw err;
      }),
    enabled: !!fondo?.id
  })

  const deleteElement = (id) => axios.delete(deleteHandPayEndpoint + id);

  const printMutation = useMutation({
    mutationKey: ['print_handpay'],
    mutationFn: (id) => axios.get(Apis.CAJA + '/operation-handpay/' + id + '/print?printerId=' + printer?.printerId),
    onSuccess: () => {
      toast({
        title: "Impresion realizada!",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const deleteModal = useConfirmDialog(
    '¿Deseas eliminar esta operación?',
    'op_delete_handpay', //mutateKey
    'getHandpays', //volver a llamar para refrescar la data
    deleteElement // accion para ejecutar en el mutate
  );

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Juego',
        accessorKey: 'gameName'
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount ? formatNumber(row.amount, config.symbol) : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Cliente',
        accessorKey: 'customerName',
      },
      {
        header: 'Documento',
        accessorKey: 'customerDocument',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fecha',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            <Button variant='outline' size='sm'
                    onClick={() => deleteModal.handleClick(info.row.original.id)}>Eliminar</Button>
            <Button size='sm' onClick={() => printMutation.mutate(info.row.original.id)}>Imprimir</Button>
          </div>
        ),
      }
    ]
    , [deleteModal, config, printMutation]
  );

  return (
    <div className='flex flex-col flex-1 gap-4'>
      <AsyncContent
        data={data}
        isFetched={isFetched}
        isLoading={isLoading}
        isError={isError}
        error={error}
      >
        <div className='flex flex-row items-center gap-4'>
          <CardSummary
            text='Nro de Operaciones'
            value={data?.quantity}
          />
          <CardSummary
            text='Total'
            value={formatNumber(data?.totalAmount, config.symbol)}
          />
        </div>

        {data && data.data.length > 0 ?
          <ReactTable columns={columns} data={data.data} initialPageSize={50} />
          :
          <div className="relative w-full h-full overflow-hidden">
            <EmptyState />
          </div>
        }
      </AsyncContent>
      <deleteModal.ConfirmationModal/>
    </div>
  )
}

export default HisHandpay
