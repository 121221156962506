import React, {useContext, useEffect, useState} from 'react'
import {Button, FormControl, Input, Textarea} from '../../components/atoms'
import {SiteContext} from '../../context/SiteContext';
import styles from './operations.module.css';
import {useMutation} from '@tanstack/react-query';
import {Apis} from '../../utils/apis';
import axios from 'axios';
import {toast} from '../../hooks/useToast';
import {BlockLoading, SidebarHistory} from '../../components/blocks';
import HisExpenses from "../historial/hisExpenses";
import useEndpoint, {salasBeta} from "../../hooks/useEndpoint";

const Expenses = () => {

  const {fondo, printer, salaId} = useContext(SiteContext);
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [expenseCategoryId, setExpenseCategoryId] = useState(null);
  const [expenses, setExpenses] = useState([]);

  const expensesCategoryMutation = useMutation({
    mutationKey: ['op_get_expenses_category'],
    mutationFn: (data) => axios.get(Apis.CAJA + '/expenses-category', data),
    onSuccess: (response) => {
      localStorage.setItem('expenses', JSON.stringify(response.data));
      setExpenses(response.data);
    },
    onError: (error) => {
      console.log(error);
    }
  });

  let createExpensesEndpoint = useEndpoint('createExpenses');

  if (salasBeta(salaId)) {
    createExpensesEndpoint = `${Apis.CAJA}/operation-other`;
  }

  const expensesMutation = useMutation({
    mutationKey: ['op_others'],
    mutationFn: (data) => axios.post(createExpensesEndpoint, data),
    onSuccess: () => {
      setAmount('')
      setDescription('')
      setExpenseCategoryId(1)
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (amount) {
      expensesMutation.mutate({
        amount: amount,
        description: description,
        expenseCategoryId: expenseCategoryId,
        printerId: printer?.printerId,
        fondoId: fondo.id,
      });
    }
  };

  const getButtonStyle = (selected) => {
    return expenseCategoryId === selected ? styles.selectedButton : styles.buttonGroup;
  };

  useEffect(() => {
    const expenses = JSON.parse(localStorage.getItem('expenses'));
    console.log(expenses);
    if (expenses) {
      setExpenses(expenses);
    } else {
      expensesCategoryMutation.mutate();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='flex flex-col flex-1'>

      <form onSubmit={handleSubmit}>
        <div className='flex flex-col self-stretch flex-1 gap-6 pt-4 bg-neutral-800 rounded-2xl'>
          <FormControl>
            <label>Categoría</label>
            {expensesCategoryMutation.isPending ?
              <BlockLoading/>
              :
              <div className='flex flex-row items-center self-stretch flex-1 gap-4'>
                {expenses?.map(expense => (
                  <div key={expense.id}
                       className={getButtonStyle(expense.id)}
                       onClick={() => setExpenseCategoryId(expense.id)}>
                    {expense.name}
                  </div>
                ))}
              </div>
            }
          </FormControl>
          <FormControl className='flex-1'>
            <label>Monto</label>
            <Input
              placeholder="00.0"
              type='number'
              className='text-2xl font-semibold'
              autoComplete="off"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </FormControl>
          <FormControl className='flex-1'>
            <label>Descripción</label>
            <Textarea
              placeholder="ingrese una descripción de la operación..."
              value={description}
              maxLength={160}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
          <Button size='lg' type="submit">Guardar</Button>
          <SidebarHistory
            title='Historial - Gastos'
          >
            <HisExpenses/>
          </SidebarHistory>
        </div>
      </form>
    </div>
  )
}

export default Expenses
