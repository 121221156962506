import React, {useContext, useEffect, useRef, useState} from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  FormControl,
  Input
} from '../../components/atoms'
import {SiteContext} from '../../context/SiteContext';
import {ComboClient, DialogAddClient, SidebarHistory} from '../../components/blocks';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {toast} from '../../hooks/useToast';
import HisSaleTicket from "../historial/hisSaleTicket";
import {formatNumber, formatShortDistanceToNow, getStatusIcon} from "../../utils/general";
import {LuCopy, LuInfo} from "react-icons/lu";

const PromoTickets = ({onFocusInput}) => {

  const {fondo, config, printer, modeLocal} = useContext(SiteContext);
  const [amount, setAmount] = useState('');
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const queryClient = useQueryClient();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [confirm, setConfirm] = useState(false);

  const cloudEndpoint = Apis.CAJA + '/operation-ticket-venta';
  const localEndpoint = 'https://servidor.wargos.cloud/xticket/ticket-caja/venta';

  let defaultEndpoint = cloudEndpoint;
  if (modeLocal) {
    defaultEndpoint = localEndpoint;
  }

  const {data} = useQuery({
    queryKey: ['getTicketsPromo', fondo?.id],
    queryFn: () =>
      axios.get(Apis.CAJA + '/operation-ticket-venta/report/by-fondo', {
        params: {
          validOnly: false,
          fondoId: fondo?.id,
          restricted: true
        }
      }).then((res) => res.data)
        .catch((err) => {
          console.error('Error fetching tickets sale:', err);
          throw err; // Es importante lanzar el error para que useQuery lo maneje
        }),
    enabled: !!fondo?.id,
    refetchInterval: 2000
  })

  useEffect(() => {
    if (onFocusInput) {
      onFocusInput(() => {
        inputRef.current?.focus();
      });
    }
  }, [onFocusInput]);

  const promoTicketMutation = useMutation({
    mutationKey: ['op_ticket_sale_promo'],
    mutationFn: (data) => axios.post(defaultEndpoint, data),
    onSuccess: () => {
      setAmount('');
      toast({
        title: "Operación realizada",
        variant: 'success',
        duration: 2000
      })
      setConfirm(false);
      inputRef.current.focus();
      queryClient.invalidateQueries(['getTicketsPromo', fondo?.id]);
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (amount <= 0) {
      toast({
        title: 'El monto debe ser mayor a 0',
        variant: 'danger',
        duration: 2000
      });
      return;
    }

    if (amount) {
      const valueInCents = Math.round(parseFloat(amount) * 100);
      let finalValue = amount;
      if (modeLocal) {
        finalValue = valueInCents
      }

      promoTicketMutation.mutate({
        amount: finalValue,
        amountCents: valueInCents,
        validationType: 1,
        fondoId: fondo.id,
        computerId: printer?.computerId,
        printerId: printer?.printerId,
        customerId: selectedCustomer?.id,
      });
    }
  };


  const handleQuantitySelect = (item) => {
    setOpen(false);
  };

  const copyClipboard = (text) => {
    navigator.clipboard.writeText(text).then(r => {
      toast({
        title: "Copiado",
        variant: 'info',
        duration: 1500
      })
    });
  }

  return (
    <>
      <div className='flex flex-col self-stretch flex-1 gap-6 pt-4 bg-neutral-800 rounded-2xl'>
        <form className='flex flex-col gap-6'
              onSubmit={(e) => {
                e.preventDefault();
                setConfirm(true);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  setConfirm(true);
                }
              }}
        >
          <div className='flex flex-row items-start gap-4'>
            <div className='flex flex-col flex-1 gap-6'>
              <ComboClient setSelectedCustomer={setSelectedCustomer} selectedCustomer={selectedCustomer}/>
              <FormControl className='flex-1'>
                <label>Monto</label>
                <Input
                  placeholder="00.0"
                  type='number'
                  className='text-2xl font-semibold'
                  autoComplete="off"
                  ref={inputRef}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </FormControl>

              <Button size='lg' type="button" onClick={() => setConfirm(true)} disabled={promoTicketMutation.isPending}>
                Imprimir
                <span className='font-bold'>
                  {amount && amount !== '' && ('S/ ' + Number(amount).toFixed(2))}
                </span>
              </Button>
              <SidebarHistory
                title='Historial - Tickets promocionales'
              >
                <HisSaleTicket restricted={true}/>
              </SidebarHistory>
            </div>
            <div className='flex flex-col flex-1 gap-2 max'>
              <div className='flex flex-row items-center justify-between'>
                <span className='text-sm'>Ultimos tickets vendidos</span>
                {/* <TicketDispenser /> */}
              </div>
              <div className='flex flex-col gap-4 px-4 text-sm'>
                {data?.data.length > 0 ? (
                  data.data.slice(0, 6).map((item) => (
                    <div key={item.id} className='flex flex-row items-center flex-1 gap-2'>
                      <div className='flex flex-row items-center flex-1 gap-2'>
                        {getStatusIcon(item.stateCode)}
                        <span>{item.vnFull}</span>
                        <Button
                          size='sm'
                          variant='outline'
                          type='button'
                          className='w-8 h-8 px-0 rounded-lg'
                          onClick={() => copyClipboard(item.vnFull)}
                        >
                          <LuCopy size={14}/>
                        </Button>
                      </div>
                      <DialogAddClient id={item.ticketId}/>
                      <span className='flex-1 text-center'>
                        {formatShortDistanceToNow(item.createdAt)}
                      </span>
                      <span className='text-right'>{formatNumber(item.amount, config.symbol)}</span>
                    </div>
                  ))
                ) : (
                  <div className='flex flex-col items-center justify-center gap-2 py-4'>
                    <LuInfo size={32} className='text-amber-400'/>
                    <span className='text-center text-neutral-500'>Aún no se han realizado ventas de tickets</span>
                  </div>
                )}
              </div>
            </div>
          </div>

        </form>

        <Dialog
          open={confirm}
          onOpenChange={(isOpen) => {
            setConfirm(isOpen);
          }}
        >
          <DialogOverlay />
          <DialogContent className='flex flex-col gap-4'>
            <DialogHeader>
              <DialogTitle>Ticket Promocional</DialogTitle>
            </DialogHeader>
            <div className='flex flex-row items-center gap-2'>
              <span>Monto a vender:</span>
              <strong>{formatNumber(amount, config.symbol)}</strong>
            </div>
            <div className='flex flex-row items-center justify-end gap-2'>
              <Button
                variant='outline'
                onClick={() => setConfirm(false)}
              >
                Cancelar
              </Button>

              <Button onClick={handleSubmit}>
                Confirmar
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog open={open} onOpenChange={setOpen}>
          <DialogOverlay/>
          <DialogContent className='flex flex-col gap-4'>
            <DialogHeader>
              <DialogTitle>Seleccione cantidad</DialogTitle>
              <DialogDescription>
                Seleccione la cantidad que desee imprimir
              </DialogDescription>
            </DialogHeader>
            <div className='flex flex-col max-h-[300px] overflow-y-auto overflow-x-hidden'>
              {[1, 5, 10, 20].map((item, index) => (
                <div
                  key={index}
                  className='py-4 mx-4 text-lg border-b cursor-pointer border-neutral-500'
                  onClick={() => handleQuantitySelect(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </DialogContent>
        </Dialog>

      </div>
    </>
  )
}

export default PromoTickets
