import React, {useContext, useEffect, useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom';
import Sidebar from '../sidebar';
import {useWauth} from '../../../auth/WauthProvider';
import styles from './layout.module.css';
import axios from "axios";
import {cn} from '../../../utils/general';
import DialogTurns from '../dialog-turns';
import Intercom from "@intercom/messenger-js-sdk";
import {SiteContext} from "../../../context/SiteContext";
import {datadogRum} from "@datadog/browser-rum";
import Banner from "../banner";

axios.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem("currentUser")).token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const salaId = localStorage.getItem('salaId');

  if (salaId && salaId > 0) {
    axios.defaults.headers.common['X-Sala-ID'] = salaId
  }
  return config;
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    if (401 === error.response.status) {
      window.location = '/logout';
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
});

const Layout = () => {

  const navigate = useNavigate();
  const {isAuthenticated, loginWithRedirect} = useWauth();
  const [open, setOpen] = useState(false);

  const {salaId, userInfo} = useContext(SiteContext);

  let name = 'Anom';
  let email = 'Anom';
  let user_id = 0;
  let company = 'Anom';

  if(userInfo.user !== undefined && userInfo.user !== null){
    name = userInfo.user.name;
    email = userInfo.user.email;
    user_id = userInfo.user.id;
    company = userInfo.user.company;

    datadogRum.setUser({
      id: user_id,
      name: name,
      email: email
    })
  }

  Intercom({
    app_id: 'wn2fe72v',
    name: name,
    email: email,
    user_id: user_id,
    company: company,
    salaId: salaId
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1025) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!isAuthenticated) {
    loginWithRedirect();
    return;
  }

  const logout = () => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("name");
    localStorage.removeItem("salaId");
    localStorage.removeItem("sala");
    localStorage.removeItem("fondo");
    navigate('/logout');
  };

  return (
    <div className={styles.container}>
      <Sidebar logout={logout} open={open} setOpen={setOpen} />
      <Banner/>
      <div className={cn(styles.containerMain, open ? styles["open"] : '')}>
        <div className='max-w-[1200px] flex self-stretch flex-1'>
          <Outlet />
        </div>
      </div>
      <DialogTurns />
    </div>
  )
}

export default Layout
