import {useContext} from "react";
import {SiteContext} from "../context/SiteContext";
import {Apis} from "../utils/apis";

const localUrl = 'https://servidor.wargos.cloud';

const endpoints = {
  saleTicket: {
    cloud: `${Apis.CAJA}/operation-ticket-venta`,
    local: `${localUrl}/xticket/ticket-caja/venta`,
  },
  redeemTicket: {
    cloud: `${Apis.CAJA}/operation-ticket`,
    local: `${localUrl}/xticket/ticket-caja/redeem`
  },
  hisSaleTicket: {
    cloud: `${Apis.CAJA}/operation-ticket-venta/report/by-fondo`,
    local: 'https://servidor.wargos.cloud/xticket/ticket-report/by-fondo'
  },
  hisRedeemTicket: {
    cloud: `${Apis.CAJA}/operation-ticket/report/by-fondo`,
    local: 'https://servidor.wargos.cloud/xticket/ticket-report/by-fondo-redeem'
  },
  hisCardPay: {
    cloud: `${Apis.CAJA}/operation-cardpay/report/by-fondo`,
    local: `https://servidor.wargos.cloud/xcaja/card-pay/by-fondo`
  },
  hisHandPay: {
    cloud: `${Apis.CAJA}/operation-handpay/report/by-fondo`,
    local: `https://servidor.wargos.cloud/xcaja/hand-pay/by-fondo`
  },
  hisExpenses: {
    cloud: `${Apis.CAJA}/operation-other/report/by-fondo`,
    local: `https://servidor.wargos.cloud/xcaja/expenses/by-fondo`
  },
  handPayCurrent: {
    cloud: `${Apis.CAJA}/operation-handpay/pending`,
    local: `https://servidor.wargos.cloud/xcaja/hand-pay/pending`
  },
  createHandPay: {
    cloud: `${Apis.CAJA}/operation-handpay`,
    local: `https://servidor.wargos.cloud/xcaja/hand-pay`
  },
  createCardPay: {
    cloud: `${Apis.CAJA}/operation-cardpay`,
    local: `https://servidor.wargos.cloud/xcaja/card-pay`
  },
  createExpenses: {
    cloud: `${Apis.CAJA}/operation-other`,
    local: `https://servidor.wargos.cloud/xcaja/expenses`
  },
  disableHandPay: {
    cloud: `${Apis.TGM}/handpays/disable/`,
    local: `https://servidor.wargos.cloud/xbluereports/handpays/disable/`
  },
  deleteCardPay: {
    cloud: `${Apis.CAJA}/operation-cardpay/`,
    local: `https://servidor.wargos.cloud/xcaja/card-pay/`
  },
  deleteHandPay: {
    cloud: `${Apis.CAJA}/operation-handpay/`,
    local: `https://servidor.wargos.cloud/xcaja/hand-pay/`
  },
  deleteExpenses: {
    cloud: `${Apis.CAJA}/operation-other/`,
    local: `https://servidor.wargos.cloud/xcaja/expenses/`
  },
  getComboMachine: {
    cloud: `${Apis.TGM}/machines/combo`,
    local: `https://servidor.wargos.cloud/xbluereports/machines/combo`
  },
  getPreArqueo: {
    cloud: `${Apis.CAJA}/arqueo/pre/v2`,
    local: `https://servidor.wargos.cloud/xcaja/fondos/summary`
  }
};

const useEndpoint = (type) => {
  const { modeLocal } = useContext(SiteContext);

  return modeLocal ? endpoints[type].local : endpoints[type].cloud;
};

export default useEndpoint;

export const salasBeta = (salaId) => {
  //const salaNumber = parseInt(salaId);
  //const salaIDs = [999, 24, 90, 91, 92, 190, 260, 221, 100];

  //return !salaIDs.includes(salaNumber);

  return false;
}
